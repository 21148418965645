(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Facilities
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('Facilities', Facilities);

  function Facilities($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/facilities/:id', {id: '@_idFacility'}, {
        update: {
          method: 'PUT'
        }
      }
    );
  }
})();

